import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const SocialMedia = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Social Media",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/social-media/",
      }}
    >
      <section className="container-fluid social_section">
        <div className="text-center">
          <h1>Social Media</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              Prowadzimy profile marek na Facebooku, Instagramie i Linkedinie. W
              ramach realizowanych przez nas działań przygotowujemy oprawę
              graficzną profilu, tworzymy strategię komunikacji dopasowaną do
              Twojej grupy odbiorców i prowadzimy dyskusję, która buduje
              zaangażowanie fanów w sieci. Moderujemy społeczność, zwiększamy
              liczbę wartościowych fanów i błyskawicznie reagujemy na kryzysy.
              Działania organiczne wspieramy kampaniami płatnymi, które wpływają
              na wizerunek firmy oraz zwiększają sprzedaż.
            </p>
          </div>
        </div>
        <div className="row social_row social_img">
          <div className="col-md-8 m-auto">
            <div className="row">
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../assets/img/offer/facebook.png")}
                  alt=""
                />
              </div>
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../assets/img/offer/instagram.png")}
                  alt=""
                />
              </div>
              <div className="col-md-4 text-center mb-4">
                <img
                  className="img-fluid m-auto"
                  src={require("../../assets/img/offer/linkedin.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row social_row">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              Wszystkie nasze przedsięwzięcia w obszarze social media oparte są
              o indywidualną strategię komunikacji. Zawiera one kompleksowe
              ujęcie wizerunku marki, jej grup docelowych oraz kanałów
              komunikacji, w obrębie których powinna się poruszać. Przygotowując
              strategię szczegółowo analizujemy konkurencję oraz możliwości
              rynkowe. Na podstawie wyciągniętych wniosków określamy cel, oraz
              konkretną koncepcję.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/oferta/ads-google/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default SocialMedia;
